import { registerOp, registers, setFlags } from './cpu'
import * as memory from './memory'
import { asSignedByte, checkCarry, checkCarry2, checkHalfBorrow, checkHalfCarry, checkHalfCarry2, getBit, isZero, overflowByte, overflowByte2, setBit, toHex } from './utils'

// == 8-bit Load instructions ==

// ld r,r
registerOp(range(0x4, 0x7), range(0x0, 0xF), () => {
    const indexA = (registers.opHigh - 4) * 2 + getBit(registers.opLow, 3)
    const indexB = registers.opLow % 8

    setRegisterByIndex(indexA, getRegisterByIndex(indexB))

    if (indexA == 6 || indexB == 6)
        registers.t += 4
}, 1, 4)

// ld r,n
registerOp(range(0x0, 0x3), [0x6, 0xE], () => {
    setRegisterByIndex(registers.opHigh * 2 + getBit(registers.opLow, 3), getNextByte())
}, 2, 8)

// ld A,(BC)
registerOp(0x0, 0xA, () => {
    registers.A = memory.get(registers.BC)
}, 1, 8)

// ld A,(DE)
registerOp(0x1, 0xA, () => {
    registers.A = memory.get(registers.DE)
}, 1, 8)

// ld A,(nn)
registerOp(0xF, 0xA, () => {
    registers.A = memory.get(getNextBytes2())
}, 3, 16)

// ld (DE),A
registerOp(0x1, 0x2, () => {
    memory.set(registers.DE, registers.A)
}, 1, 8)

// ld (nn),A
registerOp(0xE, 0xA, () => {
    memory.set(getNextBytes2(), registers.A)
}, 3, 16)

// ld A,(FF00+n)
registerOp(0xF, 0x0, () => {
    registers.A = memory.get(0xFF00 + getNextByte())
}, 2, 12)

// ld (FF00+n),A
registerOp(0xE, 0x0, () => {
    memory.set(0xFF00 + getNextByte(), registers.A)
}, 2, 12)

// ld (FF00+C),A
registerOp(0xE, 0x2, () => {
    memory.set(0xFF00 + registers.C, registers.A)
}, 1, 8)

// ldi (HL),A
registerOp(0x2, 0x2, () => {
    memory.set(registers.HL, registers.A)
    registers.HL = overflowByte2(registers.HL + 1)
}, 1, 8)

// ldi A,(HL)
registerOp(0x2, 0xA, () => {
    registers.A = memory.get(registers.HL)
    registers.HL++
}, 1, 8)

// ldd (HL),A
registerOp(0x3, 0x2, () => {
    memory.set(registers.HL, registers.A)
    registers.HL = overflowByte2(registers.HL - 1)
}, 1, 8)

// ldd (HL),A
registerOp(0x3, 0xA, () => {
    memory.set(registers.A, registers.HL)
    registers.HL = overflowByte2(registers.HL - 1)
}, 1, 8)

// == 16-bit Load instructions ==

// ld rr,nn
registerOp(range(0x0, 0x3), 0x1, () => {
    setRegister16ByIndexLastSP(registers.opHigh, getNextBytes2())
}, 3, 12)

// push rr
registerOp(range(0xC, 0xF), 0x5, () => {
    registers.SP -= 2
    memory.set2(registers.SP, getRegister16ByIndexLastAF(registers.opHigh))
}, 1, 16)

// pop rr
registerOp(range(0xC, 0xF), 0x1, () => {
    setRegister16ByIndexLastAF(registers.opHigh, memory.get2(registers.SP))
    registers.SP += 2
}, 1, 12)

// == 8-bit Arithmetic/Logic instructions ==

// add A, r
registerOp(0x8, range(0x0, 0x7), () => {
    const a = registers.A
    const b = getRegisterByIndex(registers.op)
    registers.A = overflowByte(a + b)

    setFlags(isZero(registers.A), 0, checkHalfCarry(a, b), checkCarry(a, b))

    if (isHLRegisterIndex(registers.op))
        registers.t += 4
}, 1, 4)

// add A, n
registerOp(0xC, 0x6, () => {
    const a = registers.A
    const b = getNextByte()
    registers.A = overflowByte(a + b)

    setFlags(isZero(registers.A), 0, checkHalfCarry(a, b), checkCarry(a, b))

    if (isHLRegisterIndex(registers.op))
        registers.t += 4
}, 2, 8)

// and r
registerOp(0xA, range(0x0, 0x7), () => {
    registers.A = registers.A & getRegisterByIndex(registers.op)
    setFlags(isZero(registers.A), 0, 1, 0)

    if (isHLRegisterIndex(registers.op))
        registers.t += 4
}, 1, 4)

// and n
registerOp(0xE, 0x6, () => {
    registers.A = registers.A & getNextByte()

    setFlags(isZero(registers.A), 0, 1, 0)
}, 2, 4)

// xor r
registerOp(0xA, range(0x8, 0xF), () => {
    registers.A = registers.A ^ getRegisterByIndex(registers.op)
    setFlags(isZero(registers.A), 0, 0, 0)

    if (isHLRegisterIndex(registers.op))
        registers.t += 4
}, 1, 4)

// or r
registerOp(0xB, range(0x0, 0x7), () => {
    const index = registers.opLow % 8

    registers.A = registers.A | getRegisterByIndex(index)

    registers.z = isZero(registers.A)
    registers.n = 0
    registers.h = 0
    registers.c = 0

    if (index == 6)
        registers.t += 4
}, 1, 4)

// or n
registerOp(0xF, 0x6, () => {
    registers.A = registers.A | getNextByte()

    setFlags(isZero(registers.A), 0, 0, 0)
}, 2, 8)

// cp n
registerOp(0xF, 0xE, () => {
    const n = getNextByte()

    registers.z = registers.A == n ? 1 : 0
    registers.n = 1
    registers.h = checkHalfBorrow(registers.A, n)
    registers.c = registers.A < n ? 1 : 0
}, 2, 8)

// inc r
registerOp(range(0x0, 0x3), [0x4, 0xC], () => {
    const index = registers.opHigh * 2 + getBit(registers.opLow, 3)

    const a = getRegisterByIndex(index)
    const b = 1
    const result = overflowByte(a + b)

    setRegisterByIndex(index, result)

    registers.z = isZero(result)
    registers.n = 0
    registers.h = checkHalfCarry(a, b)
}, 1, op => op == 0x34 ? 12 : 4)

// dec r
registerOp(range(0x0, 0x3), [0x5, 0xD], () => {
    const index = registers.opHigh * 2 + getBit(registers.opLow, 3)

    const a = getRegisterByIndex(index)
    const b = overflowByte(-1)
    const result = overflowByte(a + b)

    setRegisterByIndex(index, result)

    registers.z = isZero(result)
    registers.n = 1
    registers.h = checkHalfBorrow(a, 1)
}, 1, op => op == 0x35 ? 12 : 4)

// cpl
registerOp(0x2, 0xF, () => {
    registers.A = registers.A ^ 0xFF

    setFlags(null, 1, 1, null)
}, 1, 4)

// == 16-bit Arithmetic/Logic instructions ==

// add HL, rr
registerOp(range(0x0, 0x3), 0x9, () => {
    const a = registers.HL
    const b = getRegister16ByIndexLastSP(registers.opHigh)
    registers.HL = overflowByte2(a + b)

    setFlags(null, 0, checkHalfCarry2(a, b), checkCarry2(a, b))
}, 1, 8)

// inc rr
registerOp(range(0x0, 0x3), 0x3, () => {
    const index = registers.opHigh

    let num = getRegister16ByIndexLastSP(index)
    num = overflowByte2(num + 1)
    setRegister16ByIndexLastSP(index, num)
}, 1, 8)

// dec rr
registerOp(range(0x0, 0x3), 0xB, () => {
    const index = registers.opHigh

    let num = getRegister16ByIndexLastSP(index)
    num = overflowByte2(num - 1)
    setRegister16ByIndexLastSP(index, num)
}, 1, 8)

// == Rotate and Shift instructions ==

// rla
registerOp(0x1, 0x7, () => {
    const bit7 = getBit(registers.A, 7)
    registers.A = (registers.A << 1) & 0xFF + registers.c
    setFlags(0, 0, 0, bit7)
}, 1, 4)

// cb __
registerOp(0xC, 0xB, () => {
    const cbOp = getNextByte()
    const cbOpHigh = cbOp >> 4
    const cbOpLow = cbOp & 0xF
    const cbOpRightSide = cbOpLow >= 0x8

    const registerIndex = cbOpLow % 8
    const registerValue = getRegisterByIndex(registerIndex)
    if (cbOpHigh == 0x2 && !cbOpRightSide) {
        const carry = getBit(registerValue, 7)
        const newValue = ((registerValue << 1) & 0xFF) | 0x01

        setRegisterByIndex(registerIndex, newValue)
        setFlags(isZero(newValue), 0, 0, carry)
    } else if (cbOpHigh == 0x3 && !cbOpRightSide) {
        const high = registerValue >> 4
        const low = registerValue & 0xF

        const result = (low << 4) | high
        setRegisterByIndex(registerIndex, result)

        setFlags(isZero(result), 0, 0, 0)
    } else if (cbOpHigh >= 0x4 && cbOpHigh <= 0x7) {
        const bitIndex = (cbOpHigh % 4) * 2 + (cbOpRightSide ? 1 : 0)
        const bitValue = getBit(registerValue, bitIndex)

        setFlags(isZero(bitValue) ? 1 : 0, 0, 1, null)
    } else if (cbOpHigh == 0x8 && !cbOpRightSide) {
        setRegisterByIndex(registerIndex, setBit(registerValue, 0, 0))
    } else {
        console.log('MISSING CB OP: ' + toHex(cbOp))
    }

    if (registerIndex == 6)
        registers.t += 8
}, 2, 8)

// == Single-bit Operation instructions ==

// == CPU Control instructions ==

// NOP
registerOp(0, 0, () => {

}, 1, 4)

// halt
registerOp(0x7, 0x6, () => {
    // TODO: Implement HALT
    console.log('HALT!')
}, 1, 4)

// di
registerOp(0xF, 0x3, () => {
    registers.IME = 0
}, 1, 4)

// ei
registerOp(0xF, 0xB, () => {
    registers.IME = 1
}, 1, 4)

// == Jump instructions ==

// jp nn
registerOp(0xC, 0x3, () => {
    registers.PC = getNextBytes2()
}, 0, 16)

// jp HL
registerOp(0xE, 0x9, () => {
    registers.PC = registers.HL
}, 0, 4)

// jp f,nn
registerOp([0xC, 0xD], [0x2, 0xA], () => {
    const index = (registers.opHigh % 4) * 2 + getBit(registers.opLow, 3)
    if (getFlagByIndex(index)) {
        registers.PC = getNextBytes2()
        registers.t += 4
    } else {
        registers.PC += 3
    }
}, 0, 12)

// jr PC+dd
registerOp(0x1, 0x8, () => {
    registers.PC += asSignedByte(getNextByte())
    registers.t += 4
    registers.PC += 2
}, 0, 12)

// jr f,PC+dd
registerOp([0x2, 0x3], [0x0, 0x8], () => {
    const index = getBit(registers.opHigh, 0) * 2 + getBit(registers.opLow, 3)
    if (getFlagByIndex(index)) {
        registers.PC += asSignedByte(getNextByte())
        registers.t += 4
    }
    registers.PC += 2
}, 0, 8)

// call nn
registerOp(0xC, 0xD, () => {
    registers.SP -= 2
    memory.set2(registers.SP, registers.PC + 3)
    registers.PC = getNextBytes2()
}, 0, 24)

// ret
registerOp(0xC, 0x9, () => {
    registers.PC = memory.get2(registers.SP)
    registers.SP += 2
}, 0, 16)

// ret f
registerOp([0xC, 0xD], [0x0, 0x8], () => {
    const index = (registers.opHigh % 4) * 2 + getBit(registers.opLow, 3)
    if (getFlagByIndex(index)) {
        registers.PC = memory.get2(registers.SP)
        registers.SP += 2
        registers.t += 12
    } else {
        registers.PC++
    }
}, 0, 8)

// reti
registerOp(0xD, 0x9, () => {
    registers.PC = memory.get2(registers.SP)
    registers.SP += 2
    registers.IME = 1
}, 0, 16)

// rst n
registerOp(range(0xC, 0xF), [0x7, 0xF], () => {
    registers.SP -= 2
    memory.set2(registers.SP, registers.PC + 1)
    registers.PC = registers.op - 0xC7
}, 0, 16)

// == Utils ==

function range(min: number, max: number): number[] {
    const result: number[] = []
    for (let i = min; i <= max; i++)
        result.push(i)
    return result
}

function getNextByte() {
    return memory.get(registers.PC + 1)
}

function getNextBytes2() {
    return memory.get2(registers.PC + 1)
}

function getRegisterByIndex(index: number): number {
    switch (index % 8) {
        case 0: return registers.B;
        case 1: return registers.C;
        case 2: return registers.D;
        case 3: return registers.E;
        case 4: return registers.H;
        case 5: return registers.L;
        case 6: return memory.get(registers.HL);
        case 7: return registers.A;
    }
    throw 'WUT'
}

function setRegisterByIndex(index: number, value: number) {
    switch (index % 8) {
        case 0: registers.B = value; return;
        case 1: registers.C = value; return;
        case 2: registers.D = value; return;
        case 3: registers.E = value; return;
        case 4: registers.H = value; return;
        case 5: registers.L = value; return;
        case 6: memory.set(registers.HL, value); return;
        case 7: registers.A = value; return;
    }
    throw 'WUT'
}

function isHLRegisterIndex(index: number): boolean {
    return (index % 8) == 6
}

function getRegister16ByIndexLastSP(index: number): number {
    switch (index % 4) {
        case 0: return registers.BC;
        case 1: return registers.DE;
        case 2: return registers.HL;
        case 3: return registers.SP;
    }
    throw 'WUT'
}

function setRegister16ByIndexLastSP(index: number, value: number) {
    switch (index % 4) {
        case 0: registers.BC = value; return;
        case 1: registers.DE = value; return;
        case 2: registers.HL = value; return;
        case 3: registers.SP = value; return;
    }
    throw 'WUT'
}

function getRegister16ByIndexLastAF(index: number): number {
    switch (index % 4) {
        case 0: return registers.BC;
        case 1: return registers.DE;
        case 2: return registers.HL;
        case 3: return registers.AF;
    }
    throw 'WUT'
}

function setRegister16ByIndexLastAF(index: number, value: number) {
    switch (index % 4) {
        case 0: registers.BC = value; return;
        case 1: registers.DE = value; return;
        case 2: registers.HL = value; return;
        case 3: registers.AF = value; return;
    }
    throw 'WUT'
}

function getFlagByIndex(index: number): boolean {
    switch (index % 8) {
        case 0: return registers.z == 0;
        case 1: return registers.z == 1;
        case 2: return registers.c == 0;
        case 3: return registers.c == 1;
    }
    throw 'WUT'
}
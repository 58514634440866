import * as memory from './memory'
import * as utils from './utils'

const tilesGrpahic: { ctx: CanvasRenderingContext2D, buffer: Uint8ClampedArray, imageData: ImageData }[] = []

let screenCtx: CanvasRenderingContext2D | null = null

export function showTiles() {
    if (tilesGrpahic.length == 0) {
        const tilesContainer = document.getElementById('tiles')!

        for (let tile = 0; tile < 0x100; tile++) {
            const canvas = document.createElement('canvas')
            canvas.width = 8
            canvas.height = 8
            canvas.style.imageRendering = 'pixelated'
            canvas.style.width = '16px'
            canvas.style.height = '16px'
            tilesContainer.appendChild(canvas)

            const ctx = canvas.getContext('2d')!

            const buffer = new Uint8ClampedArray(8 * 8 * 4)
            const imageData = new ImageData(buffer, 8)

            tilesGrpahic.push({ ctx, buffer, imageData })

            if (tile % 16 == 15)
                tilesContainer.appendChild(document.createElement('br'))
        }
    }

    const tilesBase = 0x8000

    for (let tile = 0; tile < 0x100; tile++) {
        const { ctx, buffer, imageData } = tilesGrpahic[tile]

        let bufferI = 0

        const tileBase = tilesBase + tile * 0x10

        for (let y = 0; y < 8; y++) {
            const lowByte = memory.get(tileBase + y * 2)
            const highByte = memory.get(tileBase + y * 2 + 1)

            for (let x = 0; x < 8; x++) {
                const color = (utils.getBit(highByte, 7 - x) << 1) | utils.getBit(lowByte, 7 - x)

                if (color == 0) {
                    buffer[bufferI++] = 255
                    buffer[bufferI++] = 255
                    buffer[bufferI++] = 255
                    buffer[bufferI++] = 255
                } else if (color == 1) {
                    buffer[bufferI++] = 192
                    buffer[bufferI++] = 192
                    buffer[bufferI++] = 192
                    buffer[bufferI++] = 255
                } else if (color == 2) {
                    buffer[bufferI++] = 96
                    buffer[bufferI++] = 96
                    buffer[bufferI++] = 96
                    buffer[bufferI++] = 255
                } else if (color == 3) {
                    buffer[bufferI++] = 0
                    buffer[bufferI++] = 0
                    buffer[bufferI++] = 0
                    buffer[bufferI++] = 255
                }
            }
        }


        ctx.putImageData(imageData, 0, 0)
    }
}

export function showScreen() {
    showTiles()

    if (screenCtx == null) {
        const canvas = document.getElementById('canvas') as HTMLCanvasElement
        screenCtx = canvas.getContext('2d')!
    }

    const base = 0x9800

    for (let y = 0; y < 18; y++) {
        for (let x = 0; x < 20; x++) {
            const tile = memory.get(base + y * 0x20 + x)
            const tileImageData = tilesGrpahic[tile].imageData

            screenCtx.putImageData(tileImageData, x * 8, y * 8)
        }
    }
}
import * as utils from "./utils"
import * as memory from './memory'
import * as cpu from './cpu'
import * as gpu from './gpu'
import * as gameboy from './gameboy'

// @ts-ignore
window.utils = utils
// @ts-ignore
window.memory = memory
// @ts-ignore
window.cpu = cpu
// @ts-ignore
window.gpu = gpu
// @ts-ignore
window.gameboy = gameboy

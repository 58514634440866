import * as cpu from './cpu'
import * as memory from './memory'
import * as screen from './screen'
import { getBit } from './utils'

enum Mode {
    HorizontalBlank = 0,
    VerticalBlank = 1,
    ScanlineOAM = 2,
    ScanlineVRAM = 3
}

const state = {
    mode: Mode.ScanlineOAM,
    modeClock: 0,
    get line() {
        return memory.get(0xFF44)
    },
    set line(value: number) {
        memory.set(0xFF44, value)
    }
}

export function step() {
    if (getBit(cpu.registers.LCDC, 7) == 0) {
        state.line = 0
        state.mode = Mode.ScanlineOAM
        state.modeClock = 0
        return
    }

    state.modeClock += cpu.registers.t

    switch (state.mode) {
        case Mode.ScanlineOAM:
            if (state.modeClock >= 80) {
                state.mode = Mode.ScanlineVRAM
                state.modeClock = 0
            }
            break

        case Mode.ScanlineVRAM:
            if (state.modeClock >= 172) {
                state.mode = Mode.HorizontalBlank
                state.modeClock = 0
            }
            break

        case Mode.HorizontalBlank:
            if (state.modeClock >= 204) {
                state.line++

                if (state.line >= 143) {
                    state.mode = Mode.VerticalBlank

                    cpu.registers.IF |= 0x01
                    screen.showScreen()
                } else {
                    state.mode = Mode.ScanlineOAM
                }
                state.modeClock = 0
            }
            break

        case Mode.VerticalBlank:
            if (state.modeClock >= 456) {
                state.modeClock = 0
                state.line++

                if (state.line > 153) {
                    state.line = 0
                    state.mode = Mode.ScanlineOAM
                }
            }
            break
    }
}
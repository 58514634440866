import * as utils from "./utils"

const memory = new Uint8Array(0x10000)

export function get(offset: number): number {
    return memory[offset]
}

export function set(offset: number, value: number): void {
    memory[offset] = value
}

export function get2(offset: number): number {
    return utils.getBytes2(memory, offset)
}

export function set2(offset: number, value: number): void {
    utils.setBytes2(memory, offset, value)
}

export function loadROM(rom: Uint8Array): void {
    memory.set(rom, 0)
}
import * as memory from './memory'
import * as utils from './utils'

export const registers = {
    buf: new Uint8Array(6 * 2),

    get A() { return this.buf[1] },
    set A(val) { this.buf[1] = val },

    get F() { return this.buf[0] },
    set F(val) { this.buf[0] = val },

    get B() { return this.buf[3] },
    set B(val) { this.buf[3] = val },

    get C() { return this.buf[2] },
    set C(val) { this.buf[2] = val },

    get D() { return this.buf[5] },
    set D(val) { this.buf[5] = val },

    get E() { return this.buf[4] },
    set E(val) { this.buf[4] = val },

    get H() { return this.buf[7] },
    set H(val) { this.buf[7] = val },

    get L() { return this.buf[6] },
    set L(val) { this.buf[6] = val },

    get SP() { return utils.getBytes2(this.buf, 8) },
    set SP(val) { utils.setBytes2(this.buf, 8, val) },

    get PC() { return utils.getBytes2(this.buf, 10) },
    set PC(val) { utils.setBytes2(this.buf, 10, val) },

    get AF() { return utils.getBytes2(this.buf, 0) },
    set AF(val) { utils.setBytes2(this.buf, 0, val) },

    get BC() { return utils.getBytes2(this.buf, 2) },
    set BC(val) { utils.setBytes2(this.buf, 2, val) },

    get DE() { return utils.getBytes2(this.buf, 4) },
    set DE(val) { utils.setBytes2(this.buf, 4, val) },

    get HL() { return utils.getBytes2(this.buf, 6) },
    set HL(val) { utils.setBytes2(this.buf, 6, val) },

    get z() { return utils.getBit(this.F, 7) },
    set z(val) { this.F = utils.setBit(this.F, 7, val) },

    get n() { return utils.getBit(this.F, 6) },
    set n(val) { this.F = utils.setBit(this.F, 6, val) },

    get h() { return utils.getBit(this.F, 5) },
    set h(val) { this.F = utils.setBit(this.F, 5, val) },

    get c() { return utils.getBit(this.F, 4) },
    set c(val) { this.F = utils.setBit(this.F, 4, val) },

    IME: 0,

    get IE() { return memory.get(0xFFFF) },
    set IE(val) { memory.set(0xFFFF, val) },

    get IF() { return memory.get(0xFF0F) },
    set IF(val) { memory.set(0xFF0F, val) },

    get LCDC() { return memory.get(0xFF40) },
    set LCDC(val) { memory.set(0xFF40, val) },

    op: 0,
    opHigh: 0,
    opLow: 0,

    // instruction time
    t: 0
}

const ops: { [op: number]: () => void } = {}

export let clock = 0

export function reset() {
    registers.A = 0x01
    registers.z = 1
    registers.n = 0
    registers.h = 1
    registers.c = 1
    registers.B = 0x00
    registers.C = 0x13
    registers.D = 0x00
    registers.E = 0xD8
    registers.H = 0x01
    registers.L = 0x4D
    registers.PC = 0x100
    registers.SP = 0xFFFE
    registers.IE = 0x0
    registers.IF = 0xE0
    registers.LCDC = 0x91

    clock = 0

    updateOp()
}

export function setFlags(z: null | 0 | 1, n: null | 0 | 1, h: null | 0 | 1, c: null | 0 | 1): void {
    if (z != null) registers.z = z
    if (n != null) registers.n = n
    if (h != null) registers.h = h
    if (c != null) registers.c = c
}

function updateOp() {
    registers.op = memory.get(registers.PC)
    registers.opHigh = registers.op >> 4
    registers.opLow = registers.op & 0xF
}

export function step() {
    let success

    updateIO()

    registers.t = 0

    if (registers.op in ops) {
        ops[registers.op]()
        success = true
    } else {
        console.log(`Unkown op! ${utils.toHex(registers.op, 1)}`)
        success = false
    }

    checkInterrupts()

    clock += registers.t

    updateOp()

    return success
}

function updateIO() {
    memory.set(0xFF00, memory.get(0xFF00) | 0xf) // JOYPAD
}

function checkInterrupts() {
    if (registers.IME) {
        let interruptedBit = -1

        for (let bit = 0; bit <= 4; bit++) {
            if (utils.getBit(registers.IE, bit) == 1 && utils.getBit(registers.IF, bit) == 1) {
                interruptedBit = bit
                break
            }
        }

        if (interruptedBit != -1) {
            registers.IF = utils.setBit(registers.IF, interruptedBit, 0)

            registers.SP -= 2
            memory.set2(registers.SP, registers.PC)

            registers.PC = 0x40 + 0x08 * interruptedBit

            registers.IME = 0

            registers.t += 5 * 4
        }
    }
}

export function registerOp(high: number | number[], low: number | number[], method: () => void, pcInc: number, clockInc: number | ((number) => number)) {
    if (!Array.isArray(high))
        high = [high]

    if (!Array.isArray(low))
        low = [low]

    for (let highI of high) {
        for (let lowI of low) {
            const op = (highI << 4) + lowI

            let clockIncNum = 0

            if (typeof clockInc == "number")
                clockIncNum = clockInc
            else
                clockIncNum = clockInc(op)

            const opMethod = () => {
                method()

                registers.t = clockIncNum
                registers.PC += pcInc
            }

            ops[op] = opMethod
        }
    }
}

export function isOpSupported(op: number): boolean {
    return op in ops
}
import * as cpu from './cpu'
import './cpuOps'
import * as memory from './memory'
import * as gpu from './gpu'

export function loadROM(rom: Uint8Array) {
    memory.loadROM(rom)
}

export function step() {
    let success = cpu.step()
    gpu.step()

    return success
}

export function reset() {
    cpu.reset()
}